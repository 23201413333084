import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getCanvasHeight from 'editor/src/store/canvas/selector/getCanvasHeight';
import getCanvasWidth from 'editor/src/store/canvas/selector/getCanvasWidth';
import addNewAddonToPageOperation from 'editor/src/store/design/operation/addNewAddonToPageOperation';
import addNewImageAssetToPageOperation from 'editor/src/store/design/operation/addNewImageAssetToPageOperation';
import addNewImageToPageOperation from 'editor/src/store/design/operation/addNewImageToPageOperation';
import { ContentAddress, Coords, Page } from 'editor/src/store/design/types';
import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import isDragElementToSpreadOnlyAllowed from 'editor/src/store/editor/selector/isDragElementToSpreadOnlyAllowed';
import { DraggableItemType, DraggableItem, SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getBackgroundColor from 'editor/src/store/hostSettings/selector/getBackgroundColor';
import { PluginName } from 'editor/src/store/plugins/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricTextComponent from 'editor/src/component/EditorArea/fabricComponents/FabricTextComponent';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import FabricRectComponent from '../../fabricComponents/FabricRectComponent';
import zIndex from '../zIndex';

import BlankElements from './BlankElements';
import BleedElement from './Bleed';
import ContentElement, { DRAG_STROKE_COLOR } from './Content';
import { getFocusedFoldingAreaAsPolygon, getVisibleAreaAsPolygons } from './contentClippingUtils';
import Diecut from './Diecut';
import FoldingLine from './FoldingLine';
import HoleElement from './Hole';
import SpreadBackgroundColor from './SpreadBackgroundColor';

interface Props {
  page: Page;
  hasBackgroundImage: boolean;
  spreadIndex: number;
  pageIndex: number;
  spreadWidth: number;
  spreadHeight: number;
  spreadCoords: Coords;
  canvasRotation: CanvasRotation;
  focusedContentAddress: ContentAddress | undefined;
}

function PageElement({
  page,
  spreadWidth,
  spreadHeight,
  spreadCoords,
  hasBackgroundImage,
  canvasRotation,
  focusedContentAddress,
  spreadIndex,
  pageIndex,
}: Props) {
  const dispatch = useDispatch();
  const { mm2px } = useFabricUtils();
  const {
    addElementsAllowed,
    showBleeds,
    anyElementSelected,
    canvasWidth,
    canvasHeight,
    backgroundColor,
    dragElementToSpreadOnlyAllowed,
  } = useSelector(
    (state) => ({
      addElementsAllowed: isAddElementsAllowed(state),
      showBleeds: getSettingsValue(state, SettingsProperty.showBleeds),
      anyElementSelected: getSelectedElementUuids(state).length > 0,
      canvasWidth: getCanvasWidth(state),
      canvasHeight: getCanvasHeight(state),
      backgroundColor: getBackgroundColor(state),
      dragElementToSpreadOnlyAllowed: isDragElementToSpreadOnlyAllowed(state),
    }),
    shallowEqual,
  );
  const hasMockups = useSelector((state) => !!state.mockup.mockup.sizes.length);

  const pageCoords = useMemo(
    () => ({
      left: spreadCoords.left + mm2px(page.x || 0),
      top: spreadCoords.top + mm2px(page.y || 0),
    }),
    [mm2px, spreadCoords, page.x, page.y],
  );

  const dropToPage = useCallback(
    (item: DraggableItem) => {
      if (item.itemType === DraggableItemType.image) {
        if (item.isMultipleItems) {
          batch(() => {
            item.itemIds.forEach((itemId) => {
              dispatch(
                addNewImageToPageOperation(spreadIndex, 0, itemId, item.dropCanvasCoords.x, item.dropCanvasCoords.y),
              );
            });
          });
        } else {
          dispatch(
            addNewImageToPageOperation(spreadIndex, 0, item.itemId, item.dropCanvasCoords.x, item.dropCanvasCoords.y),
          );
        }
      } else if (item.itemType === DraggableItemType.assetImage) {
        if (item.sourceId && item.itemThumbnail && !item.isMultipleItems) {
          void dispatch(
            addNewImageAssetToPageOperation(
              spreadIndex,
              0,
              item.itemId,
              item.sourceId as PluginName,
              item.itemThumbnail,
              item.dropCanvasCoords,
              item.dimensions,
            ),
          );
        }
      } else if (!item.isMultipleItems) {
        void dispatch(
          addNewAddonToPageOperation(spreadIndex, 0, item.itemId, item.dropCanvasCoords.x, item.dropCanvasCoords.y),
        );
      }
    },
    [spreadIndex],
  );

  const { content, foldingline, blank, diecut, bleed, hole: holes } = page.groups;

  const focusedContent =
    focusedContentAddress && focusedContentAddress.pageIndex === pageIndex
      ? content?.[focusedContentAddress.contentIndex]
      : undefined;

  const bleedClipPolygons = useMemo(
    () =>
      focusedContent
        ? getFocusedFoldingAreaAsPolygon(
            (foldingline || [])[0],
            focusedContent,
            pageCoords,
            canvasWidth,
            canvasHeight,
            mm2px,
            canvasRotation,
          )
        : undefined,
    [focusedContent, foldingline, pageCoords, canvasWidth, canvasHeight, mm2px, canvasRotation],
  );

  const visibleAreaPolygons = useMemo(
    () => getVisibleAreaAsPolygons([page], spreadCoords, mm2px, canvasRotation),
    [spreadCoords, mm2px, canvasRotation],
  );

  const [isDraggingOverSpread, setIsDraggingOverSpread] = useState(false);
  const onSpreadDragOver = useCallback(
    (isDraggingOver) => {
      if (!dragElementToSpreadOnlyAllowed) {
        setIsDraggingOverSpread(false);
        return;
      }
      setIsDraggingOverSpread(isDraggingOver);
    },
    [dragElementToSpreadOnlyAllowed],
  );

  return (
    <>
      {hasMockups && (
        <FabricTextComponent
          text={`${page.width} x ${page.height} px`}
          width={spreadWidth}
          left={spreadCoords.left}
          top={spreadCoords.top - 13}
          fontSize={10}
          textAlign="center"
          fill="rgba(51, 51, 51, 0.5)"
          fontFamily="Roboto, Arial, sans-serif"
          zIndex={0}
          evented={false}
        />
      )}
      {(!hasBackgroundImage || page.backgroundColor) && (
        <SpreadBackgroundColor
          page_nr={page.page_nr}
          contents={page.groups.content}
          bleeds={page.groups.bleed}
          mediabox={page.groups.mediabox}
          focusedContent={focusedContent}
          pageCoords={pageCoords}
          backgroundColor={page.backgroundColor}
          canvasRotation={canvasRotation}
        />
      )}
      {blank && (
        <BlankElements
          blanks={blank}
          pageCoords={pageCoords}
          canvasRotation={canvasRotation}
          showBlankAreaShadowOnly={false}
        />
      )}
      {diecut?.map((diecut, index) => (
        <Diecut key={index} diecut={diecut} pageCoords={pageCoords} canvasRotation={canvasRotation} />
      ))}
      {foldingline?.map((foldingLine, index) => (
        <FoldingLine key={index} element={foldingLine} pageCoords={pageCoords} canvasRotation={canvasRotation} />
      ))}
      {(showBleeds || anyElementSelected) &&
        bleed?.map((bleed, index) => (
          <BleedElement
            key={index}
            bleed={bleed}
            pageCoords={pageCoords}
            canvasRotation={canvasRotation}
            polygonClip={bleedClipPolygons}
          />
        ))}
      {holes?.map((hole, index) => (
        <HoleElement
          key={index}
          hole={hole}
          pageCoords={pageCoords}
          canvasRotation={canvasRotation}
          color={backgroundColor}
          contentAreaPolygon={visibleAreaPolygons.contentArea}
        />
      ))}
      {content?.map((content, index) => {
        if (content.type === 'sample' || (focusedContent && focusedContent !== content)) {
          return null;
        }

        return (
          <ContentElement
            key={index}
            content={content}
            spreadCoords={spreadCoords}
            pageCoords={pageCoords}
            enableDrop={addElementsAllowed}
            hasBackgroundImage={hasBackgroundImage}
            anyElementSelected={anyElementSelected}
            isEmpty={!page.groups.media?.length}
            onDrop={dropToPage}
            onDraggingOver={onSpreadDragOver}
            dragElementToSpreadOnlyAllowed={dragElementToSpreadOnlyAllowed}
            canvasRotation={canvasRotation}
          />
        );
      })}
      {isDraggingOverSpread && (
        <FabricRectComponent
          evented={false}
          stroke={DRAG_STROKE_COLOR}
          strokeWidth={2}
          fill=""
          left={spreadCoords.left}
          top={spreadCoords.top}
          width={spreadWidth}
          height={spreadHeight}
          zIndex={zIndex.CONTENT}
        />
      )}
    </>
  );
}

export default React.memo(PageElement);
