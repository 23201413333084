import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getMultiOption from 'editor/src/store/variants/helpers/getMultiOption';
import { ProductControlMulti } from 'editor/src/store/variants/types';

import updateMultiOptionsOperation from './updateMultiOptionsOperation';

const selectAllMultiOptionsOperation =
  (productControl: ProductControlMulti) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const variantState = state.variants;
    const { configuration, product, selectedSingleOptions } = variantState;

    if (configuration.singleSelection) {
      throw new Error('singleSelection configuration is not applicable to select all operation');
    }

    const optionsValues = productControl.options.map((controlOption) =>
      getMultiOption(
        controlOption.value,
        product,
        selectedSingleOptions,
        !configuration.singleSelection && productControl.dependsOnSingleControls,
      ),
    );
    const multiOptions = {
      ...variantState.selectedMultiOptions,
      ...{ [productControl.key]: optionsValues },
    };

    dispatch(updateMultiOptionsOperation(multiOptions));
  };

export default selectAllMultiOptionsOperation;
