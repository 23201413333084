import { DesignData, Spread } from 'editor/src/store/design/types';

const findEmptySpreadIndexes = (design: DesignData) => {
  const result: number[] = design.spreads.reduce((acc: number[], spread: Spread, spreadIndex: number) => {
    const { media } = spread.pages[0].groups;
    if (!media || media.every((element) => element.type === 'image' && !element.imageId)) {
      acc.push(spreadIndex);
    }

    return acc;
  }, []);
  return result;
};

export default findEmptySpreadIndexes;
