import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'editor/src/component/Checkbox';
import { ControlOptionProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';

import styles from './index.module.scss';

function CheckboxElement({ option, selected, disabled, toggle, control, unavailable }: ControlOptionProps) {
  const toggleCb = useCallback(() => toggle(control, option), [control, option]);
  const canToggle = !disabled || (!!unavailable && selected);
  const { t } = useTranslation();

  const className = cn(styles.CheckboxElement, `cy-checkbox-${option.value}`, {
    [styles.active]: selected,
    [styles.enabled]: !disabled,
    'cy-active': selected,
  });

  const checkbox = (alone: boolean) => (
    <Checkbox
      className={alone ? className : undefined}
      label={option.title}
      on={selected}
      disabled={!canToggle}
      onChange={toggleCb}
    />
  );

  if (unavailable && selected) {
    return (
      <div className={className}>
        {checkbox(false)}
        {unavailable.map((unavailableMessage, index) => (
          <div key={index} className={styles.unavailable}>
            {t(unavailableMessage)}
          </div>
        ))}
      </div>
    );
  }

  return checkbox(true);
}

export default React.memo(CheckboxElement);
