import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';
import deselectAllMultiOptionsOperation from 'editor/src/store/variants/operation/deselectAllMultiOptionsOperation';
import selectAllMultiOptionsOperation from 'editor/src/store/variants/operation/selectAllMultiOptionsOperation';
import selectMultiOptionOperation from 'editor/src/store/variants/operation/selectMultiOptionOperation';
import selectSingleOptionOperation from 'editor/src/store/variants/operation/selectSingleOptionOperation';
import getIsProductResizable from 'editor/src/store/variants/selector/getIsProductResizable';
import getResizableProductForGivenVariant from 'editor/src/store/variants/selector/getResizableProductForGivenVariant';
import {
  VariationProductControl,
  ProductControlMulti,
  ProductControlOption,
  ExternalProductControl,
  Product,
} from 'editor/src/store/variants/types';

import { PopupName } from 'editor/src/component/Popup/popups';

import ProductSizeControl from './ManualControls/ProductSizeControl';
import {
  getMultiControl,
  getControlElement,
  getSingleControl,
  getAvailableOptions,
  getUnAvailableOptions,
} from './utils';

interface Props {
  controlsUIOrder: string[];
  productControls: VariationProductControl[];
  externalProductControls: ExternalProductControl[];
  multiMode: boolean;
  confirmOnControlChange: Product['confirmOnControlChange'];
  disableVariationControls: boolean;
  disableVariationControlsDescription?: string;
}

function ProductControlList({
  productControls,
  externalProductControls,
  controlsUIOrder,
  multiMode,
  disableVariationControls,
  disableVariationControlsDescription,
  confirmOnControlChange,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const store = useStore();
  const selectedSingleOptions = useSelector((state) => state.variants.selectedSingleOptions);
  const getConfirmModalData = useCallback(
    (
      control: VariationProductControl,
      option: ProductControlOption,
    ): { message: string; messageTitle: string } | undefined => {
      let confirmModalData;
      confirmOnControlChange?.forEach(({ controlKey, values }) => {
        if (controlKey !== control.key) {
          return;
        }

        values.forEach(({ value, message, messageTitle }) => {
          if (option.value === value) {
            confirmModalData = { message, messageTitle };
          }
        });
      });

      return confirmModalData;
    },
    [confirmOnControlChange],
  );

  const requestConfirm = useCallback(
    (control: VariationProductControl, option: ProductControlOption): Promise<boolean> => {
      const confirmModalData = getConfirmModalData(control, option);
      return new Promise((resolve) => {
        const spreads = store.getState().design.designData?.spreads || [];
        const designDataTouched = spreads.some((spread) => !!spread.pages?.[0].groups.media?.length);
        if (confirmModalData && designDataTouched && selectedSingleOptions[control.key] !== option.value) {
          dispatch(
            openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
              popupTitle: confirmModalData.messageTitle,
              textLines: [confirmModalData.message],
              onCancel: () => resolve(false),
              options: [
                { title: t('editor-cancel') }, // txt_cancel
                {
                  title: t('Continue'),
                  onConfirm: () => resolve(true),
                },
              ],
              hideTitleIcon: true,
            }),
          );
        } else {
          resolve(true);
        }
      });
    },
    [getConfirmModalData, selectedSingleOptions],
  );

  const toggleOptionMulti = useCallback(
    (control: ProductControlMulti, option: ProductControlOption) => {
      void requestConfirm(control, option).then(
        (confirmed) => confirmed && dispatch(selectMultiOptionOperation(control, option)),
      );
    },
    [requestConfirm],
  );

  const selectSingleOption = useCallback(
    (control: VariationProductControl, option: ProductControlOption) => {
      void requestConfirm(control, option).then(
        (confirmed) => confirmed && dispatch(selectSingleOptionOperation(control, option)),
      );
    },
    [requestConfirm],
  );

  const selectAll = useCallback((control) => {
    dispatch(selectAllMultiOptionsOperation(control));
  }, []);

  const deselectAll = useCallback((control) => {
    dispatch(deselectAllMultiOptionsOperation(control));
  }, []);

  const isProductResizable = useSelector(getIsProductResizable);
  const resizableElement = useSelector(getResizableProductForGivenVariant);
  const variations = useSelector((state) => state.variants.product.productVariations);
  const singleOptions = useSelector((state) => state.variants.selectedSingleOptions);
  const multiOptions = useSelector((state) => state.variants.selectedMultiOptions);
  const unavailableProducts = useSelector((state) => state.variants.product.unavailableProducts);
  const disableUnavailableOptions = useSelector((state) => state.hostSettings.disableUnavailableOptions);
  const dimensions = useSelector((state) => {
    if (resizableElement) {
      return state.design.designData?.related_dimensions;
    }

    return state.design.designData?.dimensions;
  });

  const availableOptions = useMemo(
    () => getAvailableOptions(multiOptions, singleOptions, variations, controlsUIOrder, unavailableProducts),
    [multiOptions, singleOptions, variations, controlsUIOrder, unavailableProducts],
  );

  const unAvailableOptions = useMemo(
    () => getUnAvailableOptions(multiOptions, singleOptions, variations, controlsUIOrder, unavailableProducts),
    [multiOptions, singleOptions, variations, controlsUIOrder, unavailableProducts],
  );

  const unavailableMessage = t('This product variant is not available in your region');

  return (
    <>
      {controlsUIOrder.map((controlKey, index) => {
        const productControl =
          productControls.find((control) => control.key === controlKey) ||
          externalProductControls.find((control) => control.key === controlKey);

        if (!productControl) {
          return null;
        }

        if (productControl.type === 'multi') {
          const Control = getMultiControl(productControl.uiType);
          return (
            <Control
              key={productControl.key}
              control={productControl}
              element={getControlElement(productControl.uiType, multiMode)}
              multiMode={multiMode}
              toggleOption={toggleOptionMulti}
              selectAll={selectAll}
              deselectAll={deselectAll}
              variations={variations}
              singleOptions={singleOptions}
              multiOptions={multiOptions[productControl.key]}
              availableOptions={availableOptions[productControl.key]}
              unAvailableOptions={unAvailableOptions}
              disabled={disableVariationControls}
              disabledControlDescription={disableVariationControlsDescription}
            />
          );
        }

        if (productControl.type === 'single') {
          const Control = getSingleControl(productControl.uiType);
          const isDisabled =
            disableVariationControls &&
            confirmOnControlChange?.some(({ controlKey }) => controlKey === productControl.key);
          return (
            <Control
              key={productControl.key}
              control={productControl}
              element={getControlElement(productControl.uiType, multiMode)}
              toggleOption={selectSingleOption}
              value={singleOptions[productControl.key]}
              unavailableMessage={unavailableMessage}
              // do not disable the first single selection control
              availableOptions={
                index !== 0 && disableUnavailableOptions ? availableOptions[productControl.key] : undefined
              }
              unAvailableOptions={
                index !== 0 && disableUnavailableOptions ? unAvailableOptions[productControl.key] : undefined
              }
              disabled={isDisabled}
              disabledControlDescription={isDisabled ? disableVariationControlsDescription : undefined}
            />
          );
        }

        if (
          productControl.type === 'external' &&
          productControl.key === 'product-size' &&
          dimensions &&
          (isProductResizable || resizableElement)
        ) {
          return (
            <ProductSizeControl
              key={productControl.key}
              dimensions={dimensions}
              sizeControl={productControl}
              multiMode={multiMode}
            />
          );
        }

        return null;
      })}
    </>
  );
}

export default React.memo(ProductControlList);
