import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import calendar from './calendar';
import phonecases from './phonecases';
import wallarts from './wallarts';

export type LayoutSetName = 'phonecases' | 'wallarts' | 'calendar';

const schemaSets: { [L in LayoutSetName]: LayoutSchema[] } = {
  phonecases,
  wallarts,
  calendar,
};

export default schemaSets;
