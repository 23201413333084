import { captureException } from '@sentry/react';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import getDesignDataForExport from 'editor/src/store/design/selector/getDesignDataForExport';
import getVariantWarnings from 'editor/src/store/editorModules/warnings/selector/getVariantWarnings';
import { setVariantWarningsAction } from 'editor/src/store/editorModules/warnings/slice';
import calculateDesignDataWarnings from 'editor/src/store/editorModules/warnings/utils/calculateDesignDataWarnings';
import {
  extendedWarningsToExportedWarnings,
  extendedWarningsToWarnings,
} from 'editor/src/store/editorModules/warnings/utils/warningMapper';
import getGalleryImages from 'editor/src/store/gallery/selector/getGalleryImages';
import { useStore, useDispatch, useSelector } from 'editor/src/store/hooks';

import formatDesignDataForLogging from 'editor/src/util/formatDesignDataForLogging';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

function useVariantWatcher() {
  const store = useStore();
  const dispatch = useDispatch();
  const { variationGroups, isVariantFlow, enabledWarnings, designOptionsControl } = useSelector(
    (state) => ({
      isVariantFlow: state.variants.isVariantFlow,
      variationGroups: state.variants.variationGroups,
      enabledWarnings: state.hostSettings.enabledWarnings,
      designOptionsControl: state.variants.designOptionsControl,
    }),
    shallowEqual,
  );

  const galleryInitialized = useSelector((state) => getGalleryImages(state).length > 0);

  useEffect(() => {
    if (!isVariantFlow) {
      return;
    }

    const state = store.getState();
    const { images } = state.gallery;
    const addons = state.editorModules.addons.inUse;

    let hasAllDesignData = true;

    const exportGroups = variationGroups.map((group) => ({
      title: group.title,
      linked: group.linked,
      variations: group.variationsInfo.map(({ variation, designData }) => {
        if (!designData) {
          hasAllDesignData = false;
        }

        if (designData && variation.productUid !== designData.product_uid) {
          captureException(new Error('Product UID mismatch'), {
            extra: {
              variation,
              designData: formatDesignDataForLogging(designData),
            },
          });
        }

        const extendedWarnings = designData ? calculateDesignDataWarnings(designData, state) : [];
        const warnings = extendedWarningsToWarnings(extendedWarnings);
        const warningsChanged = !isEqual(warnings, getVariantWarnings(state, variation.productUid));
        if (warningsChanged) {
          dispatch(
            setVariantWarningsAction({
              productId: variation.productUid,
              warnings,
            }),
          );
        }

        const exportDesign = designData
          ? getDesignDataForExport(designData, images, addons, extendedWarningsToExportedWarnings(extendedWarnings))
          : undefined;

        return {
          productUid: variation.productUid,
          designDataJSON: exportDesign ? JSON.stringify(exportDesign) : undefined,
          dimensions: designData?.related_dimensions || designData?.dimensions,
          pageCount: designData?.page_count_limit ? designData?.page_count : undefined,
        };
      }),
      designOptions: group.designOptions,
    }));

    if (hasAllDesignData && exportGroups.length > 0) {
      sendPostMessage('variants.variationGroups', exportGroups);
    }
  }, [isVariantFlow, variationGroups, enabledWarnings, galleryInitialized]);

  useEffect(() => {
    if (!isVariantFlow) {
      return;
    }

    sendPostMessage('variants.designOptionControls', designOptionsControl ?? []);
  }, [isVariantFlow, designOptionsControl]);
}

export default useVariantWatcher;
