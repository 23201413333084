import cn from 'classnames';
import { fabric } from 'fabric';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import { getMediaImageDPI } from 'editor/src/store/design/selector/getMediaElementDpi';
import { MediaElement } from 'editor/src/store/design/types';
import { useSelector, useStore } from 'editor/src/store/hooks';

import EventEmitter from 'editor/src/util/EventEmitter';
import limitPrecision from 'editor/src/util/limitPrecision';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import styles from './index.module.scss';

type Operation = 'move' | 'resize';

export type ObjectCoordinates = Required<fabric.Object>['oCoords'];

export const currentOperationManager = new EventEmitter<{
  objectUpdating: [element: MediaElement, oCoords: ObjectCoordinates, op: Operation];
  objectUpdateStop: [];
}>();

function ElementOperationOverlay() {
  const fabricCanvas = useFabricCanvas();
  const store = useStore();
  const { t } = useTranslation();
  const [operation, setOperation] = useState<Operation | undefined>(undefined);
  const [{ left, top, element }, setDisplay] = useState<{
    left: number;
    top: number;
    element: MediaElement | undefined;
  }>({
    left: 0,
    top: 0,
    element: undefined,
  });
  const hiddenWarnings = useSelector((state) => state.hostSettings.hiddenWarnings);

  useEffect(() => {
    function onMouseUp() {
      setOperation(undefined);
    }

    function onMoving(element: MediaElement, { tl }: ObjectCoordinates, action: Operation) {
      setOperation(action);
      setDisplay({ element, left: tl.x, top: tl.y });
    }

    currentOperationManager.on('objectUpdating', onMoving);
    currentOperationManager.on('objectUpdateStop', onMouseUp);
    fabricCanvas.on('mouse:up', onMouseUp);
    return () => {
      currentOperationManager.off('objectUpdating', onMoving);
      currentOperationManager.off('objectUpdateStop', onMouseUp);
      fabricCanvas.off('mouse:up', onMouseUp);
    };
  }, []);

  if (!operation || !element || element.type === 'line') {
    return null;
  }

  const state = store.getState();
  const limits = getCurrentDpiLevels(state);
  const galleryImage =
    element?.type === 'image' ? state.gallery.images.find((image) => image.id === element.imageId) : undefined;
  const dpi =
    element.type === 'image' && !hiddenWarnings.LowDPI && limits && galleryImage
      ? getMediaImageDPI(element, galleryImage)
      : undefined;

  let level = 'good';
  if (limits && dpi && dpi < limits.low) {
    level = 'low';
  } else if (limits && dpi && dpi < limits.medium) {
    level = 'medium';
  }

  return (
    <div
      className={cn(styles.ElementOperationOverlay, styles[level], styles[operation], 'cy-element-operation-overlay')}
      style={{
        transform: `translate3d(${Math.max(left, 0)}px,${Math.max(top, 0)}px,0)`,
      }}
    >
      {operation === 'move' && (
        <>
          <div>{`X: ${limitPrecision(element.x, 2)}`}</div>
          <div>{`Y: ${limitPrecision(element.y, 2)}`}</div>
        </>
      )}
      {operation === 'resize' && (
        <>
          <div>{`${t('editor-width')}: ${limitPrecision(element.width, 2)}`}</div>
          <div>{`${t('editor-height')}: ${limitPrecision(element.height, 2)}`}</div>
          {element.type === 'image' && galleryImage && dpi && <div>{`${t(`${level}-dpi`)}: ${dpi}`}</div>}
        </>
      )}
    </div>
  );
}

export default React.memo(ElementOperationOverlay);
