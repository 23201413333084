import React from 'react';

import { DesignOptionGroup } from 'editor/src/store/variants/types';

import DesignOptionVariationGroup from './DesignOptionVariationGroup';

interface Props {
  selectedDesignOptionValue: string;
  groups: DesignOptionGroup[];
}

function DesignOptionsVariationGroupList({ selectedDesignOptionValue, groups }: Props) {
  return (
    <>
      {groups.map((group) => (
        <DesignOptionVariationGroup
          selectedDesignOptionValue={selectedDesignOptionValue}
          designGroup={group}
          key={`${group.key}-${group.name}`}
        />
      ))}
    </>
  );
}

export default React.memo(DesignOptionsVariationGroupList);
