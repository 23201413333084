import React, { useMemo } from 'react';

import LinkButton from 'editor/src/component/LinkButton';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';

export interface Props {
  allItemsSelected: boolean;
  allowSelectAll: boolean;
  onSelectAll(deselect?: boolean): void;
  children: React.ReactNode;
}

function MultiSelectProductControlHeader({ allItemsSelected, onSelectAll, allowSelectAll, children }: Props) {
  const selectAllBtn = useMemo(() => {
    if (!allowSelectAll) {
      return null;
    }

    return allItemsSelected ? (
      <LinkButton className="cy-deselect-all" onClick={() => onSelectAll(true)}>
        Deselect all
      </LinkButton>
    ) : (
      <LinkButton className="cy-select-all" onClick={() => onSelectAll()}>
        Select all
      </LinkButton>
    );
  }, [allItemsSelected]);

  return (
    <div className="row-flex row-flex--between row-flex--middle">
      <div className={controlStyles.controlTitle}>{children}</div>
      {selectAllBtn}
    </div>
  );
}

export default React.memo(MultiSelectProductControlHeader);
