import { Tuple, combineReducers, configureStore } from '@reduxjs/toolkit';
import { reducer as toastrReducer } from 'react-redux-toastr';

import environment, { EnvEnum } from 'editor/src/environment';

import createI18n from '../createI18n';

import actionLoglistenerMiddleware from './actionLogListener';
import appReducer from './app/slice';
import batchedSubscribeEnhancer from './batchedSubscribeEnhancer';
import canvasReducer from './canvas/slice';
import collectionsReducer from './collections/slice';
import designReducer from './design/slice';
import designListener from './designListener';
import editorReducer from './editor/slice';
import editorModulesReducer from './editorModules';
import rightbarReducer from './editorModules/rightbar/slice';
import fontsReducer from './fonts/slice';
import galleryReducer from './gallery/slice';
import gridDesignsReducer from './gridConfig/slice';
import hostSettingsReducer from './hostSettings/slice';
import mockupReducer from './mockup/slice';
import pluginsReducer from './plugins/slice';
import variantsReducer from './variants/slice';

const i18n = createI18n(environment.translationsUrl);

export type ThunkArg = { i18n: typeof i18n };
const extraThunkArgument: ThunkArg = { i18n };

const rootReducer = combineReducers({
  app: appReducer,
  canvas: canvasReducer,
  design: designReducer,
  editor: editorReducer,
  hostSettings: hostSettingsReducer,
  gallery: galleryReducer,
  editorModules: editorModulesReducer,
  gridDesigns: gridDesignsReducer,
  collections: collectionsReducer,
  toastr: toastrReducer,
  plugins: pluginsReducer,
  rightbar: rightbarReducer,
  fonts: fontsReducer,
  variants: variantsReducer,
  mockup: mockupReducer,
});

export function createReduxStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: { extraArgument: extraThunkArgument },
        serializableCheck: {
          // added this to avoid redux related errors in console.
          // TODO it makes sense to move popup outside the state and use context instead
          ignoredActions: ['app/openPopupAction'],
          ignoredPaths: ['app.popup.data.onCancel', /^app\.popup\.data\.options\.\d+\.onConfirm$/],
          ignoredActionPaths: ['payload.i18n'],
        },
      }).prepend(designListener(i18n).middleware, actionLoglistenerMiddleware.middleware),
    enhancers: (getDefaultEnhancers) => {
      const enhancers = getDefaultEnhancers();
      return new Tuple(enhancers[0], batchedSubscribeEnhancer());
    },
    devTools: environment.env === EnvEnum.dev,
  });
}

const store = createReduxStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

if (environment.env === EnvEnum.dev) {
  (window as any).reduxStore = store;
}

export const initState = store.getState();

export default store;
