import { DesignData } from 'editor/src/store/design/types';
import { AutoFillData } from 'editor/src/store/editor/operation/autoFillOperation';
import getLayoutSchemasBySlotNumbers from 'editor/src/store/editor/selector/getLayoutSchemasBySlotNumbers';
import { GalleryImage } from 'editor/src/store/gallery/types';

import { RootState } from 'editor/src/store';

import findEmptySpreadIndexes from './findEmptySpreadIndexes';
import findNotUsedImages from './findNotUsedImages';

const applyAutoFill = (designData: DesignData, galleryImages: GalleryImage[], state: RootState) => {
  let imageIndex = 0;
  const autoFillData: AutoFillData[] = [];
  const IMAGES_TO_BE_PLACED = 1;

  const emptySpreadIndexes = findEmptySpreadIndexes(designData);
  const layoutSchemas = getLayoutSchemasBySlotNumbers(state, [IMAGES_TO_BE_PLACED], true);
  const notUsedImages = findNotUsedImages(galleryImages, designData);
  designData.spreads.forEach((spread, index) => {
    if (emptySpreadIndexes?.some((spreadIndex) => spreadIndex === index)) {
      spread.pages.forEach((page, pageIndex) => {
        if (page.groups.content) {
          autoFillData.push({
            pageIndex,
            spreadIndex: index,
            layoutSchema: layoutSchemas[1],
            images: notUsedImages.slice(imageIndex, imageIndex + IMAGES_TO_BE_PLACED),
          });
          imageIndex += IMAGES_TO_BE_PLACED;
        }
      });
    }
  });
  return autoFillData;
};

export default applyAutoFill;
