import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getDefaultSpreadTemplateToAdd from 'editor/src/store/design/operation/getDefaultSpreadTemplateToAdd';
import getDesignData from 'editor/src/store/design/selector/getDesignData';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import getGalleryImages from 'editor/src/store/gallery/selector/getGalleryImages';
import { GalleryImage } from 'editor/src/store/gallery/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import applyAutoFill from 'editor/src/util/autofill/applyAutoFill';

import applyAutoFillOperation from './applyAutoFillOperation';

export interface AutoFillData {
  pageIndex: number;
  spreadIndex: number;
  layoutSchema: LayoutSchema;
  images?: GalleryImage[];
}

const autoFillOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const designData = getDesignData(state);
  const { selectedImagesIds } = state.gallery;

  const galleryImages =
    selectedImagesIds.length > 1
      ? getGalleryImages(state).filter((image) => selectedImagesIds.includes(image.id))
      : getGalleryImages(state);
  const defaultSpreadTemplateToAdd = getDefaultSpreadTemplateToAdd(state);
  if (!designData || !galleryImages || !defaultSpreadTemplateToAdd) {
    return;
  }
  const autoFillData = applyAutoFill(designData, galleryImages, state);

  batch(() => {
    dispatch(saveUndoRedoStateOperation('Auto fill'));
    dispatch(applyAutoFillOperation(autoFillData));
  });
};

export default autoFillOperation;
