import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { isCustomTab, isNotDefaultTab, isPluginTab, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import isTabActive from 'editor/src/util/isTabActive';

import IconMockup from 'editor/src/component/Icon/IconMockup';
import IconPersonsalisation from 'editor/src/component/Icon/IconPersonsalisation';
import IconProducts from 'editor/src/component/Icon/IconProducts';
import IconScene from 'editor/src/component/Icon/IconScene';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import Tab from './Tab';
import AddonsMobileTab from './Tab/AddonsMobileTab';
import AddonsTab from './Tab/AddonsTab';
import CalendarEventsTab from './Tab/CalendarEventsTab';
import CollectionsTab from './Tab/CollectionsTab';
import FoilLayersTab from './Tab/FoilLayersTab';
import FormatsTab from './Tab/FormatsTab';
import GalleryTab from './Tab/GalleryTab';
import LayersTab from './Tab/LayersTab';
import LayoutsMobileTab from './Tab/LayoutsMobileTab';
import LayoutsTab from './Tab/LayoutsTab';
import MobileCalendarEventsTab from './Tab/MobileCalendarEventsTab';
import MobileGalleryTab from './Tab/MobileGalleryTab';
import MobilePersonalizeLayersTab from './Tab/MobilePersonalizeLayersTab';
import MobilePersonalizeTab from './Tab/MobilePersonalizeTab';
import PersonalizeLayersTab from './Tab/PersonalizeLayersTab';
import MobileProductTab from './Tab/ProductMobileTab';
import ProductTab from './Tab/ProductTab';
import SettingsTab from './Tab/SettingsTab';
import ShapesMobileTab from './Tab/ShapesMobileTab';
import ShapesTab from './Tab/ShapesTab';
import ShutterstockTab from './Tab/ShutterstockTab';
import TextsTab from './Tab/TextsTab';
import VariantsTab from './Tab/VariantsTab';
import WarningsTab from './Tab/WarningsTab';

function TabElements() {
  const tabs = useSelector((state) => state.editorModules.sidebar.tabs);
  const allowAddElements = useSelector(isAddElementsAllowed);
  const activeTab = useSelector(getSidebarActiveTab);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  useEffect(() => {
    if (!activeTab && !isMobile) {
      dispatch(setSidebarActiveTabOperation(0));
    }
  }, [activeTab, tabs]);

  const elementTabs = tabs.map((tabInfo, i) => {
    const pluginName = isPluginTab(tabInfo) ? tabInfo.plugin : undefined;

    const customTabInfo = isCustomTab(tabInfo) ? tabInfo : undefined;
    const tabId = isPluginTab(tabInfo) || isCustomTab(tabInfo) ? tabInfo.id : tabInfo;
    const isActive = isTabActive(activeTab, tabInfo);

    let SidebarTab;
    switch (tabId) {
      case TAB_NAMES.PRODUCT:
        SidebarTab = isMobile ? (
          <MobileProductTab
            key={`${tabId}-${i}`}
            pluginName={pluginName}
            customTabInfo={customTabInfo}
            active={isActive}
          />
        ) : (
          <ProductTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} />
        );

        break;
      case TAB_NAMES.VARIANTS:
        SidebarTab = (
          <VariantsTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} active={isActive} />
        );
        break;
      case TAB_NAMES.LAYERS:
        SidebarTab = (
          <LayersTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} active={isActive} />
        );
        break;
      case TAB_NAMES.SETTINGS:
        SidebarTab = <SettingsTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} />;
        break;
      case TAB_NAMES.SHUTTERSTOCK:
        SidebarTab = <ShutterstockTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} />;
        break;
      case TAB_NAMES.WARNINGS:
        SidebarTab = <WarningsTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} />;
        break;
      case TAB_NAMES.GALLERY:
        SidebarTab = isMobile ? (
          <MobileGalleryTab
            key={`${tabId}-${i}`}
            pluginName={pluginName}
            customTabInfo={customTabInfo}
            active={isActive}
          />
        ) : (
          <GalleryTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} active={isActive} />
        );
        break;
      case TAB_NAMES.LAYOUTS:
        SidebarTab = isMobile ? (
          <LayoutsMobileTab
            key={`${tabId}-${i}`}
            pluginName={pluginName}
            customTabInfo={customTabInfo}
            active={isActive}
          />
        ) : (
          <LayoutsTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} active={isActive} />
        );
        break;
      case TAB_NAMES.ADDONS:
        SidebarTab = isMobile ? (
          <AddonsMobileTab
            key={`${tabId}-${i}`}
            pluginName={pluginName}
            customTabInfo={customTabInfo}
            active={isActive}
          />
        ) : (
          <AddonsTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} active={isActive} />
        );
        break;
      case TAB_NAMES.TEXTS:
        if (!allowAddElements && isMobile) {
          break;
        }
        SidebarTab = <TextsTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} />;
        break;
      case TAB_NAMES.FORMATS:
        SidebarTab = <FormatsTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} />;
        break;
      case TAB_NAMES.COLLECTIONS:
        SidebarTab = <CollectionsTab key={`${tabId}-${i}`} pluginName={pluginName} />;
        break;
      case TAB_NAMES.SHAPES:
        SidebarTab = isMobile ? (
          <ShapesMobileTab key={`${tabId}-${i}`} active={isActive} />
        ) : (
          <ShapesTab key={`${tabId}-${i}`} pluginName={pluginName} customTabInfo={customTabInfo} />
        );
        break;
      case TAB_NAMES.SCENE:
        SidebarTab = <Tab key={`${tabId}-${i}`} name={TAB_NAMES.SCENE} title={t('Scene')} icon={<IconScene />} />;
        break;
      case TAB_NAMES.PLACEHOLDER:
        SidebarTab = (
          <Tab key={`${tabId}-${i}`} name={TAB_NAMES.PLACEHOLDER} title={t('Product')} icon={<IconProducts />} />
        );
        break;
      case TAB_NAMES.MOCKUP:
        SidebarTab = (
          <Tab key={`${tabId}-${i}`} name={TAB_NAMES.MOCKUP} title={t('Mockup size')} icon={<IconMockup />} />
        );
        break;
      case TAB_NAMES.PERSONALIZE:
        SidebarTab = isMobile ? (
          <MobilePersonalizeTab key={`${tabId}-${i}`} isActive={isActive} />
        ) : (
          <Tab
            key={`${tabId}-${i}`}
            name={TAB_NAMES.PERSONALIZE}
            title={t('Personalize')}
            icon={<IconPersonsalisation active={isActive} />}
          />
        );
        break;
      case TAB_NAMES.PERSONALIZE_LAYERS:
        SidebarTab = isMobile ? (
          <MobilePersonalizeLayersTab key={`${tabId}-${i}`} isActive={isActive} />
        ) : (
          <PersonalizeLayersTab key={`${tabId}-${i}`} isActive={isActive} />
        );
        break;
      case TAB_NAMES.FOIL_LAYERS:
        SidebarTab = isMobile ? (
          <MobilePersonalizeLayersTab key={`${tabId}-${i}`} isActive={isActive} />
        ) : (
          <FoilLayersTab key={`${tabId}-${i}`} isActive={isActive} />
        );
        break;
      case TAB_NAMES.CALENDAR_EVENTS:
        SidebarTab = isMobile ? (
          <MobileCalendarEventsTab key={`${tabId}-${i}`} />
        ) : (
          <CalendarEventsTab key={`${tabId}-${i}`} />
        );
        break;
      default:
        if (isNotDefaultTab(tabInfo) && !SidebarTab) {
          SidebarTab = (
            <Tab
              key={`${tabId}-${i}`}
              name={customTabInfo?.id as TAB_NAMES}
              title={customTabInfo?.title || ''}
              pluginName={pluginName}
              customTabInfo={customTabInfo}
            />
          );
          break;
        }
    }

    return SidebarTab;
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{elementTabs}</>;
}

export default TabElements;
