import React from 'react';

import { MenuItem } from 'editor/src/component/Menu/type';
import useFilteredMenuItems from 'editor/src/component/Menu/useFilteredMenuItems';
import ButtonBackgroundColor, {
  canShow as backgroundColorCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonBackgroundColor';

import DesignSavingStatus, { canShow as savingStatusCanShow } from '../../DesignSavingStatus';

import styles from './index.module.scss';

const MENU_ITEMS: MenuItem[] = [
  { Component: DesignSavingStatus, canShow: savingStatusCanShow },
  { Component: ButtonBackgroundColor, canShow: backgroundColorCanShow },
];

function LeftSectionMobile() {
  const { items, menuContext } = useFilteredMenuItems(MENU_ITEMS);

  return (
    <div className={styles.leftSectionMobile}>
      {items.map(({ Component, mode }, i) => (
        <Component key={i} isMobile mode={mode || 'icon'} context={menuContext} />
      ))}
    </div>
  );
}

export default React.memo(LeftSectionMobile);
